import React from 'react'
import Image from "utils/Image"

import Breadcrumb from './Breadcrumb'
import SimpleBanner from './SimpleBanner'
import TestimonialPreview from './TestimonialPreview'
import RichTextRenderer from 'utils/RichTextRenderer'
import { storyblokEditable } from "gatsby-source-storyblok"

import '../../static/styles/blog-post.css'

export default ({ testimonial, content, next, breadcrumbItems }) => {

  return <>
    <div {...storyblokEditable(content)} className="container blog-post-container p-relative">
      <section className="row">
        <div
          className="blog-post order-1 col-12 col-lg-12 col-xl-12"
          style={{
            paddingBottom: '46px',
          }}
        >
          <Breadcrumb items={breadcrumbItems} />
          <h1>{testimonial.name}</h1>
        </div>
        <div className="blog-post order-2 col-12 col-lg-10 col-xl-8">
          {/* {content.hero && (
            <Image img={content.hero} alt={testimonial.name} style={{backgroundColor: "#C4C4C4"}} />
          )} */}
          <div className="post-content">
            <RichTextRenderer data={content.content} />
          </div>
        </div>
        <div className="side-banners order-4 order-xl-3 col-12 col-lg-10 col-xl-4 p-relative">
          <div className="side-banner sticky-banner">
            <SimpleBanner />
          </div>
        </div>
        <div className="blog-post social-group order-3 order-xl-4 col-12 col-lg-10 col-xl-8">
          <hr />
        </div>
        <div className="featured-posts order-5 col-12 col-lg-10 col-xl-8">
          <h4>More insights?</h4>
          <ul
            style={{
              padding: 0,
              margin: 0,
              listStyle: 'none',
            }}
          >
            {next && (
              <li className="featured-post-preview">
                <TestimonialPreview node={next} />
              </li>
            )}
          </ul>
        </div>
      </section>
    </div>
  </>;
}
