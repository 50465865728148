import React, { useState, useEffect } from "react"
import { useStoryblokState } from "gatsby-source-storyblok"
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Seo from "components/Seo"

import Testimonial from '../components/Testimonial'

const TestimonialTemplate = ({ data, pageContext, path }) => {
  // This will be used to set live editor preview data
  const [previewStory, setPreviewStory] = useState(null);

  // Parse Storyblok data
  const testimonial = useStoryblokState(data.testimonial);

  // I can't remember why this was needed, so let's first test the Storyblok editor experience with it and then without it
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {
      setPreviewStory(null);
    }
  }, [testimonial]);

  // If inside Storyblok editor, execute this
  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {

      // Storyblok API endpoint
      const url = `https://api-us.storyblok.com/v2/cdn/stories/${data.testimonial.full_slug}?version=draft&token=${process.env.GATSBY_STORYBLOK_TOKEN}&cv=${Date.now()}`;

      // Fetch preview data from Storyblok API
      const fetchData = async () => {
        try {
          const response = await fetch(url);
          const json = await response.json();
          setPreviewStory(json.story);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchData();

      // Let's wait until the Storyblok script has loaded
      const waitForStoryblokToLoad = function () {
        if (!window.StoryblokBridge) {
          setTimeout(waitForStoryblokToLoad, 100)
        } else {
          const { StoryblokBridge } = window
          const storyblokInstance = new StoryblokBridge()

          // Update live preview when Storyblok story changes
          storyblokInstance.on('input', (event) => {
            setPreviewStory(event.story);
          })
        }
      }

      waitForStoryblokToLoad();
    }
  }, []);

  // Conditional that checks whether to show original GraphQL data or the preview data
  const content = previewStory ? previewStory.content : testimonial.content;

  const description = get(
    content,
    'description',
    `${testimonial.name} - Click to read on.`
  )

  const nextUp = get(data, 'testimonials.nodes[0]', null)

  const breadcrumbItems = [
    {
      path: '',
      name: 'Home',
    },
    {
      path: 'testimonials',
      name: 'Testimonials',
    },
    {
      path: `testimonials/${pageContext.slug}`,
      name: testimonial.name,
    },
  ]

  return (
    <>
      <Seo
        title={`Testimonial: ${testimonial.name} | QuickMail`}
        description={description}
        url={'testimonials/' + data.testimonial.slug}
      />
      <Testimonial
        testimonial={testimonial}
        content={content}
        next={nextUp}
        breadcrumbItems={breadcrumbItems}
      />
    </>
  )
}

export default TestimonialTemplate

export const TestimonialQuery = graphql`query TestimonialBySlug($slug: String!, $nextUpSlug: String) {
  testimonial: storyblokEntry(slug: {eq: $slug}) {
    id
    content
    slug
    name
  }
  testimonials: allStoryblokEntry(
    sort: {order: [DESC], fields: [published_at]}
    filter: {
      field_component: {eq: "testimonial"}
      slug: { eq: $nextUpSlug }
    }
    limit: 1
  ) {
    nodes {
      slug
      name
      content
    }
  }
}
`
